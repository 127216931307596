import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import SplashImg from '../../assets/Splash.png'
import Copy from '../../assets/Copy.svg'	
import Buy from '../../assets/widgets/Buy.svg'	
import LikeNumber from '../../assets/LikeNumber.svg'
import JokeCounter from '../../assets/JokeCounter.svg'
import Send from '../../assets/Send.svg'
import TryAgain from '../../assets/TryAgain.svg'
import Cross from '../../assets/Cross.svg'
import TryCross from '../../assets/widgets/TryCross.svg'
import HaLLMAO from '../../assets/HaLLMAO.svg'
import LikeIcon from '../../assets/Like.svg'
import Search from '../../assets/Search.svg'
import Share from '../../assets/Share.svg'
import Fav from '../../assets/Fav.svg'
import Tick from '../../assets/Tick.svg'
import { Markdown } from './Markdown.js'
import ClickAwayListener from 'react-click-away-listener'
import moment from 'moment'
import { delay, countTokens } from '../../classes/Util.js'
import { isAndroid, isIOS, isMobile, isIPad, isDesktop } from '../../classes/Platform.js'
import { randomShuffle, coords, toPoly, OutlineText, ButtonSVG, InputField, PlainButton, CategoryButton } from '../Widgets'
import './Laugh.css';

function fromNow(date) {
  const fromNow = moment(date).fromNow().replace(/ ago/, '')
  const fixed = fromNow
        .replace(/.*a few seconds.*/, 'now')
        .replace(/.*a minute.*/, '1m')
        .replace(/.*an hour.*/, '1h')
        .replace(/.*a day.*/, '1d')
        .replace(/.*a month.*/, '1mo')
        .replace(/.*a year.*/, '1y')
        .replace(/ minutes?/, 'm')
        .replace(/ hours?/, 'h')
        .replace(/ days?/, 'd')
        .replace(/ months?/, 'mo')
        .replace(/ years?/, 'y')
  ////console.log('fromNow', fromNow, '=>', fixed)
  return fixed
}

const debugLog = (...args) => {
}


class JokeEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transcript:[],
      topic: props.lastTopic,
      content: this.props.joke
    }
  }

  setJokeContainer = ref => {
    this.props.setJokeContainer(ref)
  }

  setChatEditor = editor => {
    this.chatEditor = editor
  }

  componentDidMount() {
    const { lastJoke } = this.props
  }

  componentDidUpdate(prevProps) {
    if (this.props.joke !== prevProps.joke) {
      if (this.props.joke !== this.state.content) {
        this.setState({
          content: this.props.joke
        })
      }
    }
  }

  initTranscript = () => {
    if (this.state.transcript.length === 0) {
      const lastJoke = this.props.lastJoke
      this.state.transcript.push({
        role: 'user',
        content: this.state.topic,
      })
      this.state.transcript.push({
        role: 'assistant',
        content: this.state.content
      })
      this.forceUpdate()
    }
  }
  
  sendChatAction = async () => {
    const text = this.chatEditor.getText()
    if (text) {
      this.chatEditor.setText("")
      this.initTranscript()
      this.state.transcript.push({
        role: 'user',
        content: text
      })
      const transcript = [].concat(this.state.transcript)
      debugger
      let msg = {
        role: 'assistant',
        content: "Working..."
      }
      this.state.transcript.push(msg)
      this.forceUpdate()
      debugger
      const updates = 
            await this.props.me.discussJoke({id: this.props.lastJoke.id, topic: this.props.lastJoke.topic,  transcript})
      let ups = {
      }
      const { reply, content } = updates
      msg.content = reply
      if (content) {
        this.state.transcript.push({
          role: 'user',
          content: updates.topic || this.state.topic 
        })
        this.state.transcript.push({
          role: 'assistant',
          content,
        })
      }
      this.forceUpdate()
    }
  }

  getTranscriptAsJokes = (transcript) => {
    let jokes = []
    let topic
    let id = 0
    for (const message of transcript.slice(2)) {
      const { role, content } = message
      if (role === 'assistant') {
        jokes.push({
          id,
          topic,
          content
        })
        id++
      } else {
        topic = content
      }
    }
    return jokes
  }

  render() {
    let sendButtonClassName = ''
    const {  joke, keep, copyJoke, deleteLastJoke, jokeStyle, lastJoke } = this.props
    const { topic, content, transcript } = this.state
    const tryAgain = async () => {
      this.state.transcript = []
      this.initTranscript()
      await this.props.tryAgain()
    }
    const clipPath3 = `polygon(4px 3px, calc(100% - 2px) 0%, calc(100% - 4px) 100%, 0px calc(100% - 3px)`
    const jokeElem = <div className="joke-display-container  last-joke-container">
                   <div className="joke-display trapezoid blue" >
                     <div ref={this.setJokeContainer} className='jokeContent' style={jokeStyle}>{content || joke || "Working..."}</div>
                     {!this.props.busy && <div className='tryAgainButtons tryAgainButtonsOrig'>
                                            <PlainButton icon={Tick} select={keep}/>
                                            <PlainButton className='tryAgainButton' icon={TryAgain} select={tryAgain}/>
                                            <PlainButton icon={TryCross} select={deleteLastJoke}/>
                                            <PlainButton icon={Copy} select={copyJoke}/>
                                          </div>}
                   </div>
                   <div className="joke-item-topic joke-item-topic-last">
                     <div className='joke-item-topic-content'  style={{clipPath:clipPath3}}>
                   {topic}
                     </div>
                   </div>
                 </div>
    return <div key='joke-editor' className='joke-editor'>
             <div className='gpt-chat'>
               <Messages messages={[jokeElem, this.getTranscriptAsJokes(transcript).map(x => <JokeItem key={x.id} joke={x}/>)]}/>
               <div className='gpt-chat-input' style={joke ? null: { display: 'none' }}>
                 <InputField
                   setEditor={this.setChatEditor}
                   placeholder={"How may I assist you with this joke?"}
                   send={<PlainButton className={sendButtonClassName} icon={Send} select={this.sendChatAction}/>}
                   label=""
                   onInput={this.onChatInput}
                 />
                 <div className='tryAgainButtons tryAgainButtonsNew'>
                   <PlainButton icon={Tick} select={keep}/>
                   <PlainButton className='tryAgainButton' icon={TryAgain} select={tryAgain}/>
                   <PlainButton icon={Cross} select={deleteLastJoke}/>
                   <PlainButton icon={Copy} select={copyJoke}/>
                 </div>                
               </div>
             </div>
           </div>
  }
}

class Messages extends Component {
  constructor (props) {
    super(props)
  }
  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.onSelectionChange)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }
  componentDidMount() {
    const ref = this.scrollWindow
    if (this.props.onCreate) this.props.onCreate(this)
    this.resizeObserver = new ResizeObserver(entries => {
      debugLog("resize", entries)
      let skip = false
      if (this.props.slide !== 0 && this.props.slide != 1) {
        skip = true
      }
      if (this.inScroll) skip = true
      if (ref.scrollHeight == 0) skip = true
      if (skip) {
        debugLog("resize skip")
      }
      this.inResize = true
      if (ref.scrollTop !== this.lastScrollTop) {
        debugLog("onresize: scrollTop", this.scrollWindow.scrollTop, "last", this.lastScrollTop)
      }
      if (ref.scrollHeight !== this.lastHeight) {
        debugLog("onresize: scrollHeight", this.scrollWindow.scrollHeight, "last", this.lastHeight)
        this.lastHeight = ref.scrollHeight
      }
      if (ref.clientHeight !== this.lastOffsetHeight) {
        debugLog("onresize:scroll clientHeight", ref.clientHeight, "last", this.lastOffsetHeight)
        this.lastOffsetHeight = ref.clientHeight
      }
      const newScrollTop = this.computeScrollTop()
      debugLog("new scroll top: ", newScrollTop)
      const newScrollBottom = ref.scrollHeight - ref.clientHeight - newScrollTop
      debugLog("current scroll bottom: ", this.scrollBottom, "=>", newScrollBottom)
      ref.scrollTop = newScrollTop
      debugLog("resize scrollTop==>", newScrollTop)
      this.inResize = false
    })
    this.scrollBottom = 0
    this.lastHeight = ref.scrollHeight
    this.lastOffsetHeight = ref.clientHeight
    ref.scrollTop = this.computeScrollTop()
    this.lastScrollTop = ref.scrollTop
    debugLog("chat mounted scroll last: ", this.lastHeight)
    const onscroll = e => {
      let sizeDelta = 0
      if (this.inResize) {
        return
      }
      if (this.lastOffsetHeight !== ref.clientHeight) {
        debugLog("scroll client height change: ", ref.clientHeight, "last", this.lastOffsetHeight)
        sizeDelta += ref.clientHeight - this.lastOffsetHeight
        this.lastOffsetHeight = ref.clientHeight
      }
      if (this.lastHeight !== ref.scrollHeight) {
        debugLog("scroll height change: ", ref.scrollHeight, "last", this.lastHeight)
        sizeDelta += ref.scrollHeight - this.lastHeight
        this.lastHeight = ref.scrollHeight
      }
      if (sizeDelta === 0) {
        this.scrollBottom = this.computeScrollBottom()
        this.lastScrollTop = ref.scrollTop
      } else {
        const newValue = this.computeScrollTop()
        debugLog("onscroll scrollTop==>", newValue)
        return ref.scrollTop = newValue
      }
      debugLog("scrolltop=>", ref.scrollTop)
      debugLog("scrollHeight=>", ref.scrollHeight)
      debugLog("scrollClient=>", ref.clientHeight)
      debugLog("scrollBottom=>", this.scrollBottom)
      this.checkScrollBack()
    }
    ref.onscroll = e => {
      this.inScroll = true
      onscroll(e)
      this.inScroll = false
    }
    this.resizeObserver.observe(this.scrollWindow)
    this.resizeObserver.observe(this.scrollContent)
    this.windowListener = window.addEventListener("resize", this.onResized)
    this.scrollToBottom()
  }

  pushScrollBottom() {
    this.savedScrollBottom = this.scrollBottom
    //debugLog("pushScrollBottom: ", this.savedScrollBottom)
  }

  fixupScrollTopLater = () => {
    clearTimeout(this.fixupTimeout)
    setTimeout(this.fixupScrollTop)
  }

  setScrollWindow = ref => {
    if (ref && ref != this.scrollWindow) {
      this.scrollWindow = ref
    }
  }

  checkScrollBack = async () => {
    const ref = this.scrollWindow
    if (ref.scrollTop === 0 || ref.scrollHeight <= ref.offsetHeight) {
      //debugger
      if (this.props.checkScrollBack) {
        this.props.checkScrollBack()
      }
    }
  }
  
  setScrollContent = ref => {
    if (ref && ref != this.scrollContent) {
      this.scrollContent = ref
    }
  }

  computeScrollTop = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - this.scrollBottom - ref.clientHeight
    debugLog(`computeScrollTop ${ref.scrollHeight} - ${this.scrollBottom} - ${ref.clientHeight} = ${result}`)
    return result
  }

  computeScrollBottom = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - ref.clientHeight - ref.scrollTop
    debugLog(`computeScrollBottom ${ref.scrollHeight} - ${ref.clientHeight} - ${ref.scrollTop} = ${result}`)
    return result
  }
  
  fixupScrollTop = () => {
    this.scrollWindow.scrollTop = this.computeScrollTop()
  }

  scrollToBottom=()=> {
    this.scrollBottom = 0
    this.fixupScrollTop()
  }

  render() {
    const messages = this.props.messages
    let containerClass = 'uiChatMessagesContainer'
    let marginClass = 'uiChatMarginBottom'
    if (this.props.searchFieldVisible) {
       marginClass += ' uiChatMarginBottomSearchField'
    }
    if (this.props.selectedThread) {
      marginClass += ' uiChatMarginBottomThread'
    }
    let topic
    if (this.props.selectedThread) {
      topic = this.props.selectedThread.topic
    }
    const onSwipeRight = (e) => {
      if (this.props.selectedThread) {
        this.props.selectThread(null)
      }
    }
    return <div key={this.props.key}  className={marginClass} >
             <div className={'uiChatMessages'} ref={this.setScrollWindow}>
               <div key='chatMessagesContainer' className={containerClass} ref={this.setScrollContent}>
                 {messages}
               </div>
             </div>
           </div>
   }

}


class JokeItem extends Component {

  constructor(props) {
    super(props)
    const { id, topic, content, ts, utcOffset } =  this.props.joke
    const deg = Math.random() * 18;
    const index = this.props.index
    const randInt = i => Math.max(Math.round(Math.random()*i), 1)
    let fst = index % 2 === 0 ? 4 : 3
    let snd = index % 2 === 0 ? 3 : 3
    let x1 = randInt(fst)
    let y1 = randInt(fst)
    let x2 = randInt(snd)
    let y2 = randInt(snd)
    this.coords = {
      x1, y1, x2, y2
    }
    this.state = {
      orangeHeight: 50
    }
  }

  componentDidMount() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        //debugger
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        } else {
          entry.target.classList.remove('fade-in');
        }
      });
    }, {
      threshold: 0.1  // Trigger when 10% of the element is in view
    });
    this.observer.observe(this.ref)
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect()
  }

  setRef = ref => {
    this.ref = ref
  }

  select = async () => {
    this.minHeight = this.ref.clientHeight
    await this.props.tryAgain()
    delete this.minHeight
    this.forceUpdate()
  }

  render() {
    const { id, topic, content, ts, utcOffset, liked, likes, shared, usage } =  this.props.joke
    const { isYourJokes, share, copy, like, isRecent } = this.props
    const { x1, y1, x2, y2 } = this.coords
    const clipPath = `polygon(${x1}px ${y1}px, calc(100% - ${y2}px) 0%, 100% 100%, ${x2}px 100%)`
    console.log({clipPath})
    const left = `${x1}px`
    const right = `calc(100% - ${x2}px)`
    const top = `${y1}px`
    const bottom = `calc(100% - ${y2}px)`
    const { minHeight } = this
    const style = {
      clipPath,
      //clip: `rect(${top}, ${right}, ${bottom}, ${left})`
      minHeight
    }
    console.log({style})
    const clipPath1 = `polygon(5px 3px, calc(100% - 3px) 0%, 100% 100%, 3px 100%)`
    const clipPath2 = `polygon(5px 2px, calc(100% - 0px) 0%, calc(100% - 3px) 100%, 5px 100%)`
    //const clipPath3 = `polygon(4px 3px, calc(100% - 2px) 0%, calc(100% - 4px) 100%, 0px calc(100% - 3px)`
    if (!this.props.joke.coords) {
      this.props.joke.coords = toPoly(randomShuffle(coords))
    }
    let clipPath3 = this.props.joke.coords
    let likeClass = liked ? 'liked' : 'notLiked'
    let shareClass = shared ? 'shared': 'notShared'
    let buttonClass = 'joke-item-no-buttons'
    if (this.props.tryAgain || this.props.deleteJoke) {
      buttonClass = 'joke-item-buttons'
    }
    let topicStyle 
    if (false) {
      topicStyle = {
        transform: `translate(0, -${this.state.orangeHeight}px)`
      }
    }
    buttonClass += ' ' + likeClass + ' ' + shareClass
    let buttonStyle
    let inputTokens
    let outputTokens
    let dollars = 0
    let inputDollars = 0
    let outputDollars = 0
    if (isYourJokes && usage) {
      inputTokens = usage.inputTokens
      outputTokens = usage.outputTokens
      inputDollars = (3 / (1000*1000)) * inputTokens
      outputDollars = (6 / (1000*1000)) * outputTokens
      dollars = inputDollars + outputDollars
    }
    let jokeDate = 'jokeDate'
    if (isYourJokes) {
      jokeDate += ' yourJokesDate'
    }
    return <div className='joke-item-shadow-container fade' ref={this.setRef}>
             <div className='joke-item-container'>
             <div className="joke-display-container">
               <div className="joke-item trapezoid orange withMarkdown" style={style}>
               <Markdown>{content}</Markdown>
               </div>
               <div className="joke-item-topic" style={topicStyle}>
                 <div className='joke-item-topic-content'  style={{clipPath:clipPath3}}>
                   {topic} {true ? '' : !dollars ? `` : `[${inputTokens},${outputTokens} \$${dollars.toFixed(4)}]`}
                 </div>
               </div>
             </div>
             <div className={buttonClass} style={buttonStyle}>
               {like && <PlainButton key={'like'} icon={LikeIcon} select={this.props.like}/>}
               {like && <div className='likesContainer'>
                 <PlainButton key={'likeNumber'}className='likeNumber' icon={LikeNumber}/>
                 <div className='likeCount'>{likes || 0}</div>
                        </div>}
               {share && <PlainButton key={'share'} icon={Fav} select={this.props.share}/>}
               {copy && <PlainButton key={'copy'} icon={Copy} select={this.props.copy}/>}
               {this.props.deleteJoke && <PlainButton key={'delete'} icon={Cross} select={this.props.deleteJoke}/>}
             </div>
             </div>
             {like && <div className={jokeDate}>{fromNow(ts)}</div>}
           </div>
  }
}


export class Laugh extends Component {

  constructor (props) {
    super(props)
    this.state = {
      editorHeight: 60,
      topHeight: 0,
      searchTerm: '',
      recent: true
    }
  }

  jokes = {}
  recent = {}
  topLaughs = {}

  componentDidMount() {
    this.sub = this.props.me.observeSelf().subscribe(self => {
      this.removeListeners()
      if (self) {
        this.initListeners()
      }
    })
    setTimeout(() => {
      this.isReady = true
      this.forceUpdate()
    }, 1200)
  }

  deleteJoke = async id => {
    await this.props.me.deleteJoke(id)
    this.onJokeDeleted(id)
    this.forceUpdate()
  }

  onJokeDeleted = (id, collections = ['jokes', 'recent', 'topLaughs']) => {
    debugger
    for (const c of collections) {
      delete this[c][id]
    }
    if (this.state.searchTerm) {
      const found = this.state.searchResults.find(x => x.id === id)
      if (found) {
        this.setState({
          searchResults: this.state.searchResults.filter(x => x.id !== id)
        })
      }
    }
  }

  initListeners() {
    this.jokes = {}
    this.topLaughs = {}
    this.recent = {}
    this.jokesSub = this.props.me.observeJokes().subscribe(change => {
      const { type, joke } = change
      if (type === 'removed') {
        this.onJokeDeleted(joke.id)
      } else {
        this.jokes[joke.id] = joke
        if (!joke.shared) {
          this.onJokeDeleted(joke.id, ['recent', 'topLaughs'])
        }
      }
      this.updateJokesLater()
    })
    
    this.likesSub = this.props.me.observeLikes().subscribe(change => {
      const { type, jokeId } = change
      console.log("liked", change)
      const liked = type !== 'removed'
      for (const c of ['jokes', 'recent', 'topLaughs']) {
        if (this[c][jokeId]) {
          this[c][jokeId].liked = liked
        }
      }
      if (this.state.searchResults) {
        for (const x of this.state.searchResults) {
          if (x.id === jokeId) {
            x.liked = liked
            break
          }
        }
      }
      this.updateJokesLater()
    })
    this.updateTopLaughs()
    this.newJokesSub = this.props.me.observeRecent({limit: 10}).subscribe(change => {
      const { type, joke } = change
      if (type === 'removed') {
        this.onJokeDeleted(joke.id, ['topLaughs', 'recent'])
      } else {
        this.recent[joke.id] = joke
      }
      this.updateJokesLater()
    })
  }

  removeListeners = () => {
    if (this.jokesSub) this.jokesSub.unsubscribe()
    if (this.likesSub) this.likesSub.unsubscribe()
    if (this.newJokesSub) this.newJokesSub.unsubscribe()
  }


  setTopRef = ref => {
    if (ref !== this.ref) {
      if (this.ref) {
        if (this.resizeObserver) {
          this.resizeObserver.unobserve(this.ref)
        }
      }
      this.ref = ref
      if (ref) {
        if (!this.resizeObserver) {
          let seqNum = 0
          let busy = false
          const onWindowResized = () => {
            this.forceUpdate()
          }
          window.addEventListener('resize', onWindowResized)
          this.resizeObserver = new ResizeObserver(entries => {
            if (busy) return
            try {
              ////debugger
              busy = true
              for (let entry of entries) {
                if (entry.target.clientHeight !== this.state.topHeight) {
                  this.state.topHeight = entry.target.clientHeight
                  const req = ++seqNum
                  setTimeout(() => {
                    this.state.topHeight = entry.target.clientHeight
                    if (req === seqNum) {
                      this.forceUpdate()
                    }
                  }, 33)
                }
              }
            } catch (err) {
              ////debugger
              console.error(err)
            } finally {
              busy = false
            }
          })
        }
        this.resizeObserver.observe(ref)
      }
    }
  }

  updateJokesLater = () => {
    this.forceUpdate()
  }
  
  componentWillUnmount() {
    if (this.jokesSub) {
      this.jokesSub.unsubscribe()
    }
    if (this.likesSub) {
      this.likesSub.unsubscribe()
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    window.removeEventListener('resize', this.onWindowResized)
  }

  onEditorHeightChanged = height => {
    this.setState({editorHeight: height})
  }

  getJokes = () => {
    const jokes = Object.values(this.jokes)
    jokes.sort((x, y) => {
      return y.ts - x.ts
    })
    return jokes
  }

  setInput = x => {
    this.editor = x
  }

  onInput = () => {
  }

  onBlur = () => {
  }

  search = async () => {
    this.onSearchClickAway = null
    let searching = this.state.searching
    searching = !searching
    if (!searching) {
      if (this.searchEditor) this.searchEditor.clear()
      this.setState({
        searchTerm: '',
        searching: false,
        searchResults: null
      })
    } else {
      this.setState({
        searching,
      })
    }
    await delay(0.3)
    if (isDesktop()) {
      if (this.state.searching) {
        if (this.searchEditor) this.searchEditor.focus()
      } else {
        this.editor.focus()
      }
    }
    this.onSearchClickAway = searching ? () => {
      if (this.searchEditor) this.searchEditor.clear()
      this.setState({
        searchTerm: '',
        searching: false,
        searchResults: null
      })
    }: () => {}
  }

  buyPacks = async () => {
    return await this.props.purchase()
  }

  tellJoke = async () => {
    const topic  = this.editor.getText().trim()
    if (topic) {
      const ts = Date.now()
      this.setState({
        joke: '',
        ts,
        lastTopic: topic,
        busy: true,
        clipPath: toPoly(randomShuffle(coords))
      })
      await new Promise((resolve, reject) => {
        this.props.me.tellJoke({topic, ts}, {
          onContent: content => {
            this.setState({
              joke: content,
            })
          },
          onDone: () => {
            this.editor.setText('')
            resolve()
          },
          onError: err => {
            reject(err)
          }
        })
      })
      this.setState({
        busy: false
      })
    }
  }

  setEditor = editor => {
    this.editor = editor
    if (editor && isDesktop()) {
      editor.focus()
    }
  }


  setSearchEditor = editor => {
    this.searchEditor = editor
  }

  crossSearchInput = () => {
    this.searchEditor.clear()
    this.searchJokes()
  }

  onEditorInput = () => {
    this.setState({
      jokeInProgress: this.editor.getText()
    })
  }

  seq = 0
  onSearchInput = async () => {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(this.searchJokes, 50)
    this.forceUpdate()
  }

  searchJokes = async () => {
    const searchTerm = this.searchEditor.getText().trim()
    this.setState({
      searchTerm
    })
    const req = ++this.seq
    if (searchTerm) {
      const { results } = await this.props.me.searchJokes({searchTerm, isYourJokes: this.state.yourJokes})
      console.log({searchResults: results})
      if (req !== this.seq) return
      this.setState({
        searchResults: results
      })
    } else {
      this.setState({
        searchResults: null
      })
    }
  }

  offset = 0

  topLaughs = {}


  getLastJoke = () => {
    return this.getJokes()[0]
  }


  copy = async text => {
    try {
      navigator.clipboard.writeText(text)
    } catch (ignored) {
    }
    await delay(0.5)
  }

  lastScrollTop = 0

  setViewportRef = ref => {
    if (ref) {
      if (ref !== this.ref) {
        ref.onscroll = () => {
          const scrollTop = ref.scrollTop;
          const scrollHeight = ref.scrollHeight;
          const clientHeight = ref.clientHeight;
          
          // Check if the div has reached the beginning
          if (scrollTop === 0) {
            //this.checkScrollUp()
          }
          // Check if the div has reached the end
          //console.log({scrollTopPlusClientHeight: scrollTop + clientHeight, scrollHeight})
          if (Math.round(scrollTop+clientHeight)  >= scrollHeight) {
            this.checkScrollDown()
          }
        }
      }
    }
  }

  checkScrollUp = () => {
    this.updateTopLaughs('next')
  }

  scrollBusy = false

  checkScrollDown = () => {
    if (this.state.topLaughs) {
      this.updateTopLaughs()
    } else {
      this.updateRecent()
    }
  }

  checkScrollBack = () => {

  }

  updateTopLaughsOld = async (type) => {
    if (this.scrollBusy || this.state.searchTerm) {
      return
    }
    this.scrollBusy = true
    const next = await this.props.me.getTopLaughs(type, this.topLaughs, 10)
    for (const joke of next) {
      if (this.topLaughs[joke.id]) {
        console.log('duplicate', joke)
      }
      this.topLaughs[joke.id] = joke
    }

    this.forceUpdate()
    this.scrollBusy = false
  }

  updateRecent = async (type) => {
    if (this.scrollBusy || this.state.searchTerm) {
      return
    }
    this.scrollBusy = true
    const jokes = Object.values(this.recent)
    jokes.sort((x, y) => {
      return x.ts - y.ts
    })
    let ts
    if (jokes.length > 0) {
      ts = jokes[0].ts
    }
    const next = await this.props.me.getRecent(ts, 10)
    for (const joke of next) {
      if (this.recent[joke.id]) {
        console.log('duplicate', joke)
      }
      this.recent[joke.id] = joke
    }
    this.forceUpdate()
    this.scrollBusy = false
  }

  topLaughsIndex = 0
  updateTopLaughs = async () => {
    if (this.scrollBusy || this.state.searchTerm) {
      return
    }
    this.scrollBusy = true
    const jokes = Object.values(this.topLaughs)
    jokes.sort((x, y) => {
      let cmp = x.likes - y.likes
      if (cmp) return cmp
      return x.ts - y.ts
    })
    let likes
    let ts
    if (jokes.length > 0) {
      likes = jokes[0].likes
      ts = jokes[0].ts
    }
    const next = await this.props.me.getTopLaughs(likes, ts, 10)
    for (const joke of next) {
      joke.index = this.topLaughsIndex++
      if (this.topLaughs[joke.id]) {
        console.log('duplicate', joke)
      }
      this.topLaughs[joke.id] = joke
    }
    //console.log({topLaughs: Object.values(this.topLaughs).length})
    this.forceUpdate()
    this.scrollBusy = false
  }

  selectYourJokes = async () => {
    this.setState({
      yourJokes: true,
      topLaughs: false,
      recent: false,
      searching: false
    }) 
    await delay(0.3)
  }

  selectTopLaughs = async () => {
    this.setState({
      yourJokes: false,
      recent: false,
      topLaughs: true,
      searching: false
    })
    await delay(0.3)
  }

  selectRecent = async () => {
    this.setState({
      yourJokes: false,
      topLaughs: false,
      recent: true,
      searching: false
    })
    await delay(0.3)
  }

  renderTopLaughs = (opts) => {
    const { viewportStyle } = opts
    let topLaughs
    if (this.state.searchResults) {
      topLaughs = this.state.searchResults
    } else {
      topLaughs = Object.values(this.topLaughs).filter(x => x.topic)
    }
    topLaughs.sort((x, y) => {
      return x.index - y.index
    })
    const messages = topLaughs.map((x, i) => {
      const like = async () => {
        await this.props.me.likeJoke(x)
        this.forceUpdate()
      }
      let deleteJoke
      if (this.props.me.isAdmin) {
        deleteJoke = async () => {
          await this.props.me.deleteJoke(x.id)
          this.onJokeDeleted(x.id)
        }
      }
      const copy = () => this.copy(x.content)
      return <JokeItem key={x.id+'-top'} index={i} joke={x} copy={copy} like={like} deleteJoke={deleteJoke}/>
    })
    return this.renderDown({visible: this.state.topLaughs, messages, viewportStyle, key:'topLaughs'})
  }

  renderRecent = (opts) => {
    const { viewportStyle } = opts
    let topLaughs
    if (this.state.searchResults) {
      topLaughs = this.state.searchResults
    } else {
      topLaughs = Object.values(this.recent).filter(x => x.topic)
    }
    topLaughs.sort((x, y) => {
      return y.ts - x.ts
    })
    const messages = topLaughs.map((x, i) => {
      const like = async () => {
        await this.props.me.likeJoke(x)
        this.forceUpdate()
      }
      const copy = () => this.copy(x.content)
      let deleteJoke
      if (this.props.me.isAdmin) {
        deleteJoke = async () => {
          await this.deleteJoke(x.id)
          this.onJokeDeleted(x.id)
        }
      }
      return <JokeItem key={x.id+'-top'} index={i} joke={x} copy={copy} like={like} isRecent deleteJoke={deleteJoke}/>
    })
    return this.renderDown({visible:this.state.recent, messages, viewportStyle, key:'recent'})
  }

  renderDown(opts) {
    const { messages, viewportStyle, visible, key } = opts
    return <div key={key} className='joke-viewport' style={!visible ? { display: 'none' } : viewportStyle}>
             <div className='joke-list' ref={this.setViewportRef}>
               {messages}
               <div className='renderDownFiller'/>
             </div>
           </div>
  }

  renderUp(opts) {
    const { messages, viewportStyle } = opts
    return <div key='topLaughs' className='topLaughs' style={this.state.yourJokes ? { display: 'none' } : viewportStyle}><Messages
                                                              checkScrollBack={this.checkScrollBack}
                                                                                                                           messages={messages}/></div>
  }

  setYourJokes = ref => {
    this.yourJokes = ref
  }

  render() {
    const searchResults = this.state.searchResults
    let jokeButtonClass = "joke-button"
    let jokes
    if (searchResults) {
      jokes = searchResults
    } else {
      jokes = this.getJokes()
    }
    let lastJoke = this.state.joke
    if (!lastJoke && jokes.length > 0) {
      //lastJoke = jokes[0].content
      //jokes = jokes.slice(1)
    }
    if (this.state.joke) {
    }
    let topLaughs
    if (searchResults) {
      topLaughs = searchResults
    } else {
      topLaughs = Object.values(this.topLaughs)
      topLaughs.sort((x, y) => {
        return y.ts - x.ts
      })
    }
    const clipPath1 = `polygon(0px 0px, calc(100% - 0px) 7px, calc(100% - 7px) 100%, 4px 100%)`
    const clipPath2 = `polygon(10px 6px, calc(100% - 9px) 0%, calc(100% - 1px) 100%, 0px 100%)`
    //const clipPath3 = `polygon(10px 6px, calc(100% - 0px) 7px%, calc(100% - 7px) 100%, 0px 100%)`
    let jokeContainerStyle = { display: 'none'}
    let jokeStyle
    const clipPath3 = `polygon(4px 3px, calc(100% - 2px) 0%, calc(100% - 4px) 100%, 0px calc(100% - 3px)`
    if (this.state.joke) {
      if (this.state.jokeMinHeight) {
        jokeStyle = {
          minHeight: this.state.jokeMinHeight || undefined,
          clipPath: this.state.clipPath
        }
      } else {
        jokeStyle = {
          clipPath: this.state.clipPath
        }
      }
    }
    if (this.state.lastTopic) {
      jokeContainerStyle = null
    }
    console.log({jokeContainerStyle})
    const copyJoke = async () => {
      return this.copy(this.state.joke)
    }
    const tryAgain = async () => {
      const lastJoke = this.getLastJoke()
      const { id, topic, content, ts, utcOffset } = lastJoke
      this.state.jokeMinHeight = this.jokeContainer.clientHeight - 40
      this.setState({
        tryAgainBusy: true,
        joke: "Ok, let's try this again...",
      })
      await new Promise((resolve, reject) => {
        this.props.me.tellJoke({id},
                               {
                                 onContent: content => {
                                   this.state.joke = content
                                   this.forceUpdate()
                                 },
                                 onDone: () => {
                                   lastJoke.content = content
                                   resolve()
                                 },
                                 onError: err => {
                                   reject(err)
                                 }
                               })
      })
      this.setState({
        tryAgainBusy: false,
        jokeMinHeight: undefined
      })
    }
    let viewportStyle
    let offset = 95;
    if (this.state.topHeight > 0) {
      let transform
      if (this.state.searching) {
        offset += 70
      }
      let h = window.innerHeight - this.state.topHeight - offset
      const safeAreaTop = getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top');
      const safeAreaBottom = getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom');
      if (isIOS()) {
        if (this.props.me.isNative()) {
          h -= 25
          if (this.state.searchResults && this.state.searchResults.length > 0) {
            h += 100
          }
        } else if (isIPad()) {
          h -= 30
        } else {
          h += 20
        }
      }
      if (isAndroid()) {
        h += 20;
      }
      ////debugger
      viewportStyle = {
        transform,
        height: h //`calc(${h + (isMobile() ? 10: 0)}px - ${safeAreaTop} - ${safeAreaBottom})`,
      }
    }
    if (this.state.lastTopic) {
      viewportStyle = {
        display: 'none'
      }
    }
    const keep = async () => {
      const joke = this.getLastJoke()
      await this.props.me.shareJoke(joke.id)
      this.setState({
        joke: null,
        lastTopic: null
      })
      this.selectYourJokes()
      this.yourJokes.scrollTop = 0
    }
    const deleteLastJoke = async () => {
      const joke = this.getLastJoke()
      await this.props.me.deleteJoke(joke.id)
      this.setState({
        joke: null,
        lastTopic: null
      })
    }
    let searchButtonClass = 'searchButton'
    if (this.state.searching) {
      searchButtonClass += ' searching'
    }
    let sendButtonClassName
    let sendAction = this.tellJoke
    if (!this.props.available) {
      sendButtonClassName = 'sendButtonBuy'
      sendAction = this.buyPacks
    }
    let jokeCategoryStyle
    let categoryWidth = 140 * 2 + 30
    let scale = (window.innerWidth - 20 - 55 - 15) / categoryWidth 
    if (scale < 1) {
      jokeCategoryStyle = {
        transform: `translate(-10px, 0) scale(${scale})`
      }
    }

    const searchClick = async () => {      
      this.search()
      await delay(0.3)
    }

    let Recent = 'Recent'
    let Top = 'Top'
    let Yours = 'Yours'

    const sendChatAction = async () => {
      const text = this.chatEditor.getText()
      if (text) {
        
      }
    }

    let headerStyle
    if (this.state.lastTopic) {
      headerStyle = {
        display: 'none'
      }
    }
    

    return (
      <div className='llmaoApp'>
        <div className="main-content" ref={this.setTopRef}>
          <div className="header" style={headerStyle}>
            <InputField
              setEditor={this.setEditor}
              onInput={this.onEditorInput}
              placeholder={'Choose a topic or scenario'}
              label='New Joke'
              send={
                <div className='sendButtonContainer' style={!this.isReady ?  { opacity: 0} : { opacity: 1 }}>
                  {this.props.available > 0 && <div className='likesContainer'>
                                                 <PlainButton key={'likeNumber'}className='likeNumber' icon={JokeCounter}/>
                                                 <div className='likeCount'>{this.props.available}</div>
                                               </div>}
                  {this.props.available === 0 ?
                   <div className='sendButtonBuyContainer'> 
                     <PlainButton className={sendButtonClassName} icon={Buy} select={sendAction}/>
                   </div>
                   :
                   <PlainButton className={sendButtonClassName} icon={Send} select={sendAction}/>}
                  
                </div>
              }
            />
          </div>
          {this.state.lastTopic && <JokeEditor
                                     setJokeContainer={ref => this.jokeContainer = ref }
                                     joke={this.state.joke}
                                     lastJoke={this.getLastJoke()}
                                     jokeStyle={jokeStyle}
                                     keep={keep}
                                     lastTopic={this.state.lastTopic}
                                     me={this.props.me}
                                     copyJoke={copyJoke}
                                     tryAgain={tryAgain}
                                     deleteLastJoke={deleteLastJoke}
                                     busy={this.state.busy}
                                   />
           
          }
          {!this.state.lastTopic && <div className="joke-categories" style={jokeCategoryStyle}>
                                      <CategoryButton label={Recent} select={this.selectRecent} clipPath={clipPath1} selected={this.state.recent}/>
                                      <CategoryButton label={Top} select={this.selectTopLaughs} clipPath={clipPath3} selected={this.state.topLaughs}/>
                                      <CategoryButton label={Yours} select={this.selectYourJokes} clipPath={clipPath2} selected={this.state.yourJokes}/>
                                      <div className={searchButtonClass}>
                                        <PlainButton icon={Search} select={searchClick} keepFocus/>
                                      </div>
                                    </div>
          }
        </div>
          <ClickAwayListener onClickAway={this.onSearchClickAway}>
            <div className='clickAwayFillerContainer' style={viewportStyle}>
              <div className='clickAwayFiller'/>
              {<div key='searchField' className='searchFieldDiv' style={this.state.searching && !this.state.lastTopic ? null : { display: 'none'}}>
                 <div>
                   <InputField
                     setEditor={this.setSearchEditor}
                     placeholder={this.state.yourJokes ? "Search Your Jokes" : this.state.topLaughs ? "Search Top Laughs" : "Search Recent"}
                     send={this.searchEditor && this.searchEditor.getText()  && <PlainButton className='searchCross' keepFocus icon={Cross} select={this.crossSearchInput}/>}
                     onInput={this.onSearchInput}
                   />
                 </div>
               </div>}
              <div key='viewport' className='joke-viewport' >
                <div className="joke-list" style={ this.state.yourJokes ? viewportStyle : { display: 'none' }} ref={this.setYourJokes}>
                  {
                    jokes.map((x, i) => {
                      const deleteJoke = async () => {
                        await this.props.me.deleteJoke(x.id)
                        this.onJokeDeleted(x.id)
                      }
                      console.log('joke', x)
                      const like = async () => {
                        await this.props.me.likeJoke(x)
                        this.forceUpdate()
                      }
                      const share = async () => {
                        debugger
                        await this.props.me.shareJoke(x.id)
                        this.forceUpdate()
                      }
                      const copy = () => this.copy(x.content)
                      return <JokeItem key={x.id}
                                       copy={copy}
                                       deleteJoke={deleteJoke}
                                       index={i}
                                       isYourJokes={true}
                                       joke={x}
                                       share={share}
                                       like={like}/>
                    })
                  }
                  <div className='joke-list-filler'/>
                </div>
              </div>
              {this.renderTopLaughs({offset, viewportStyle})}
              {this.renderRecent({offset, viewportStyle})}
            </div>
          </ClickAwayListener>
        </div>

    );
  }
}

